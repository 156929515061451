.table-control {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-container {
    height: 100%;
  }

  .ant-spin-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .ant-table {
      flex: 1;
      overflow: hidden;

      .ant-table-container {
        display: flex;
        flex-direction: column;

        .ant-table-body {
          flex: 1;

          table {
            height: 100%;
          }

          // .ant-table-row {
          //   height: auto;
          // }
        }
      }
    }

    .ant-pagination {
      padding: 0 10px;
    }
  }
}