.layout-page {
  height: 100%;
  min-height: 100vh;

  &-header {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    border-bottom: 1px solid #757575;

    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    &-main {
      // padding: 0 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      height: 64px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        align-items: end;
      }
    }
  }

  .logo {
    height: 64px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      align-items: end;
    }
  }

  &-sider {
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    > :nth-child(1) .ant-tabs-bar {
      padding: 6px 0 0;
    }

    > :nth-child(2) {
      flex: auto;
      overflow: auto;
      padding: 6px;
      box-sizing: border-box;

      .innerText {
        padding: 24px;
        border-radius: 2px;
        display: block;
        line-height: 32px;
        font-size: 16px;
      }
    }
  }

  &-footer {
    text-align: center;
    padding: 14px 20px;
    font-size: 12px;
  }

  .actions {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    >* {
      // margin-left: 30px;
      height: 100%;
      display: flex;
      align-items: center;

      .notice {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }

  .user-action {
    cursor: pointer;
  }

  .user-avator {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
}

body[theme-mode='dark'] {
  .layout-page-header {
    box-shadow: none;
  }
}

.layout-page-sider-menu {
  border-right: none !important;
  background: transparent;
}

.ant-menu-inline-collapsed {
  width: 79px !important;
}

.notice-description {
  font-size: 12px;

  &-datetime {
    margin-top: 4px;
    line-height: 1.5;
  }
}

.notice-title {
  display: flex;
  justify-content: space-between;
}

.tagsView-extra {
  height: 100%;
  width: 50px;
  cursor: pointer;
  display: block;
  line-height: 40px;
  text-align: center;
}

.themeSwitch {
  position: fixed;
  right: 32px;
  bottom: 102px;
  cursor: pointer;

  >span {
    display: block;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 22px;
    z-index: 10001;
  }
}

.theme-color-content {
  display: flex;

  .theme-color-block {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    border-radius: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-menu-item {
  font-family: "Jost-Medium";
  margin: 16px auto !important;
}

.ant-menu-item:hover {
  background: radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%);
  color: #fff !important;
  font-family: "Jost-Bold" !important;
  border-radius: 100px !important;
  margin: 16px auto !important;
}

.ant-menu-item-selected {
  background: radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%);
  color: #fff !important;
  font-family: "Jost-Bold" !important;
  border-radius: 100px !important;
  margin: 16px auto !important;
}

.ant-tabs-tab-active {
  background: radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%) !important;
  color: #fff !important;
  font-family: "Jost-Medium";
  padding: 10px !important;
  border-bottom-color: transparent !important;
}

.ant-tabs-tab-btn {
  color: #fff !important;
  text-shadow: none !important;
}

.ant-tabs-tab {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.ant-form-item-label>label {
  font-family: "Jost";
}

.ant-input {
  font-family: "Jost";
}

span {
  font-family: "Jost";
}

.ant-card-body {
  padding: 16px !important;
}

.ant-modal-content {
  box-shadow: 0px 0px 51px 0px #FF3C3C52 !important;
  border: 1px solid #000000 !important;
  border-radius: 32px !important;
  background: linear-gradient(0deg, #000000, #000000),
    linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    radial-gradient(66.81% 71.02% at 95.36% 100%, rgba(251, 4, 57, 0.75) 0%, rgba(0, 0, 0, 0) 100%) !important;

}

.ant-layout-sider {
  margin: 0 !important;
}

.ant-pagination-item {
  min-width: 48px !important;
  height: 48px !important;
}

.ant-pagination .ant-pagination-item a {
  padding: 8px 6px;
  font-weight: 700;
  font-size: 16px;
}

.borderLinearInput {
  background: radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  border-radius: 12px;
  padding: 1px;
  border-radius: 24px,
}

.borderLinearInput :hover {
  border: none;
}

.borderLinearInput :focus {
  border: none;
}

.borderLinearInput :active {
  border: none;
}