.base-view {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/logo/banner_base.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Jost';
  height: 100vh;
  overflow-y: scroll;

  .tabs-main {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .search {
    margin-bottom: 10px;
  }

  .aside-main {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;

    @media screen and (max-height: 800px) {
      overflow: auto;
    }
  }

  .table {
    flex: 1;
    overflow: hidden;

    @media screen and (max-height: 800px) {
      overflow: auto;
      min-height: 500px;
    }
  }
}