input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #fff !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #fff !important;
}

.ant-btn-primary:disabled {
  color: rgba(255, 255, 255, 0.8) !important;
}

.ant-pagination {
  align-items: center;
}

.ant-table-cell {
  font-size: 16px;
  font-family: 'Jost-Medium';
  letter-spacing: 1px;
}

.ant-table-wrapper .ant-table {
  background: rgba(20, 20, 20, 0.7) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background: transparent
}