@font-face {
  font-family: 'Jost-Bold';
  src: url("../assets/fonts/Jost/static/Jost-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: url(../assets/fonts/Jost/static/Jost-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body,
#root,
#root>* {
  height: 100%;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, sans-serif !important;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

#searchForm>.ant-row>.ant-row.ant-form-item {
  width: 100%;
  text-align: right;
  padding-right: 12px;

  .ant-col {
    flex: 1;
    max-width: 100%;
    width: 100%;

    .ant-btn:first-child {
      margin-right: 10px;
    }
  }
}

.app-loading-wrapper.ant-spin-spinning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textPrimary {
  background: radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}